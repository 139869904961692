<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Template Type</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-data-table :headers="headers" :items="type_list" class="elevation-1" :search="search">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-spacer></v-spacer><span style="width: 20px"> </span>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Add Template Type</v-btn>
            </template>
            <v-card>
              <v-form v-model="valid">
                <v-card-title>
                  <v-row justify="space-between">
                    <span class="headline">{{ formTitle }}</span>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                  <!-- <span class="headline">{{ formTitle }}</span> -->
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <!-- <v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                <v-autocomplete :items="type_master_list" v-model="addedItem.type_master_list" label="Select Template Type" :rules="[v => !!v || 'required']" persistent-hint
                                                        :hint="editedIndex==-1?'For example :: Student Biodata,Resume':'For example :: Student Biodata,Resume'"></v-autocomplete>
                                            </v-col>
                                           
                                              <v-col cols="12" v-if='editedIndex != -1'>Template Type
                                                <v-select dense v-model="editedItem.type" :items="type_master_list" item-value="id" persistent-hint  :rules="[v => !!v || 'required']" 
                                                        :hint="editedIndex!=-1?'For example :: Student Biodata,Resume':'For example :: Student Biodata,Resume'"></v-select>
                                            </v-col> -->

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="editedItem.type" label="Enter Template Type"
                          :rules="[(v) => !!v || 'required']" persistent-hint :hint="editedIndex == -1
                            ? 'For example :: Student Biodata,Resume'
                            : 'For example :: Student Biodata,Resume'
                            ">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox dense v-model="editedItem.isactive" color="success" label="Active"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close">Cancel</v-btn>
                  <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                  <!-- :disabled="!valid" -->
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- <template v-slot:item.isactive="{ item }">
            <span v-if="item.isactive">
              <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
            </span>
            <span v-else>
              <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
            </span>
          </template> -->
      <template v-slot:item.isactive="{ item }">
        <v-switch v-model="item.isactive" @change="activeswitch(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
      </template>
      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)">
              edit
            </v-icon>
          </v-avatar>
          <v-btn text>
            <v-icon color="red" @click="deletefun(item.id)">
              mdi-delete</v-icon></v-btn>
        </div>
      </template>
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    valid: false,
    overlay: false,
    errorMsg: {
      type: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    type_list: null,
    headers: [
      {
        text: "Template Type",
        align: "left",
        sortable: false,
        value: "type",
      },
      {
        text: "Active",
        align: "left",
        sortable: true,
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    type_list: [],
    editedIndex: -1,

    editedItem: {
      id: "",
      type: "",
      isactive: true,
    },
    defaultItem: {
      id: "",
      type: "",
      isactive: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Template Type"
        : "Edit Template Type";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = true;
      };
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    hasWhiteSpace(s) {
      var status = false;
      var reWhiteSpace = new RegExp(/^\s+$/);

      // Check for white space
      if (reWhiteSpace.test(s)) {
        alert("Please Check Your Fields For Spaces");
        //   return true;
        return /^\s/.test(s);
      }
      //   return false;
      return /^\s/.test(s);
    },
    initializeErrorMsgFalse() {
      this.errorMsg.type = false;
    },
    onLoad() {
      this.overlay = true;
      axios

        .post("/AdminMaster/addTemplateType")

        .then((res) => {
          if (res.data.msg == "200") {
            //window.alert(res.data.msg)

            if ((this.load = true)) {
              this.overlay = false;
              this.type_list = res.data.type_list;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          // window.console.log(error);
        })
        .finally(() => {
          "use strict";
          var overlay = false;
        });
    },
    editItem(item) {
      this.editedIndex = this.type_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      this.initializeErrorMsgFalse();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedItem.type == "" || /^[ \d]+$/.test(this.editedItem.type) || this.hasWhiteSpace(this.editedItem.type)) {
        this.showSnackbar(
          "red",
          " Please Enter Template Type..."
        ); // show snackbar on success
        return;
      }
      else {
        if (this.editedIndex > -1) {
          if (this.isValidated()) {
            axios
              .post("/AdminMaster/editTemplateType", this.editedItem)
              .then((res) => {
                if (res.data.status.code == "SUCCESS") {
                  // window.console.log(res.data.msg)
                  Object.assign(
                    this.type_list[this.editedIndex],
                    this.editedItem
                  );

                  this.showSnackbar(
                    "#4caf50",
                    "Template Type Updated Successfully..."
                  ); // show snackbar on success
                } else if (res.data.status.code == "NA") {
                  //window.alert(res.data.status.code)
                  this.showSnackbar(
                    "#b71c1c",
                    "Template Type Already Present!!!"
                  ); // show snackbar on error
                }
              })
              .catch((error) => {
                window.console.log(error);
              });
            this.close();
          }
        } else {
          //this.address_type_list.push(this.editedItem)
          axios
            .post("/AdminMaster/saveTemplateType", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                //window.alert(res.data.status.code)
                this.type_list.push(this.addedItem);
                this.showSnackbar(
                  "#4caf50",
                  "Tempalate Type Added Successfully!!!"
                ); // show snackbar on success
                this.onLoad();
              } else if (res.data.status.code == "NA") {
                //window.alert(res.data.status.code)
                this.showSnackbar("#b71c1c", "Template Type Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      }

    }, //save()
    isValidated() {
      if (this.editedItem.type) {
        return true;
      } else {
        if (!this.editedItem.type) {
          this.errorMsg.type = true;
        }

        return false;
      }
    }, // .....end of isValidated()

    deletefun(item) {
      const data = {
        deleteitem: item,
      };
      axios
        .post("/AdminMaster/deleteTemplateType", data)
        .then((res) => {
          // console.log("a")
          if (res.data.msg == "200") {
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onLoad();
          } else {
            //error
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    activeswitch(item) {
      // console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      //  console.log(item.id);

      axios
        .post("/AdminMaster/activetemplatetype", data)
        .then((res) => {
          // console.log("res.data");
          //   console.log(res.data);
          if (res.data.msg == "200") {
            //   console.log("res.data");
            //   console.log(res.data);
            //   console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            //  console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
